import React from "react";
import { useState} from "react";
import useCollapse from 'react-collapsed';
//import { faCaretDown, faCaretUp } from '@fortawesome/fontawesome-free-solid'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table, Tbody, Tr, Th } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import LottoHintCarousel from "components/lotto/default/LottoHintCarousel";

import DirectAdsComponent from "../../../components/lotto/default/DirectAdsComponent";


export function HintCollapsible({topic, hintDataArray, hintInsightDataArray, slug , isShowAtRender}) {
      //const { getCollapseProps, getToggleProps, isCollapsed } = useCollapse();
      //console.log("Hint:isShowAtRender = " + isShowAtRender);
      const [ isExpanded, setExpanded ] = useState(isShowAtRender);
      const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
    
      function handleOnClick() {
        // Do more stuff with the click event!
        // Or, set isExpanded conditionally 
        setExpanded(!isExpanded);
        
        //if (!isExpanded){
            window.open("https://c.lazada.co.th/t/c.bA74Ec?sub_aff_id=lazada_bonus_deeplink", "_blank");
        //}
      }
    
      return (
      <div className="collapsible">
            <div className={isExpanded ? "header-expand" : "header-collapse"} {...getToggleProps({onClick: handleOnClick})}>
                  <Table>
                    <Tbody>
                    <Tr style={{border : '0px'}}>
                        <Th className="borderRounded">
                              <h2>
                                <div style={{fontSize : '1.25rem'}}>
                                {topic}
                                </div>
                              </h2>
                              <div style={{fontSize : '0.85rem'}}> 
                              {/*
                                    {isExpanded ? "ซ่อน " : "ดูข้อมูล "} {isExpanded ? <FontAwesomeIcon icon="caret-up" /> : <FontAwesomeIcon icon="caret-down" />}
                              */}
                              {isExpanded ? "ซ่อน " : "ดูข้อมูล "}
                              </div>
                              
                          
                        </Th>
                    </Tr>
                    </Tbody>
                  </Table>
            </div>
            <div {...getCollapseProps()} >
                  
                  <div> <br/> เช็คเลขเด็ดจากคำชะโนดและปฏิทินจีนประจำงวดนี้กันเลย!!! <br/></div>
                  <div style={{  height: "360px", padding: "5px"}}>
                    {/*<LottoHint hintDataArray={hintDataArray} slug={slug} />*/}
                    <LottoHintCarousel hintDataArray={hintDataArray} hintInsightDataArray={hintInsightDataArray} slug={slug} />

                  </div>

                  <hr/>                  
                  <DirectAdsComponent adsType="lazada_bonus_deeplink"/>
                  
            </div>
            <hr/>
    
      </div>
      );
}
